import { FC, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";

const PrivateRoutes = () => {
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );

  const AgentPage = lazy(
    () => import("../modules/apps/agent-management/AgentPage")
  );

  const ProfilePage = lazy(() => import("../modules/apps/profile/ProfilePage"));

  const BonusesPage = lazy(() => import("../modules/apps/bonuses/BonusesPage"));

  const FaqPage = lazy(() => import("../modules/apps/faq/FaqPage"));

  const TransactionsPage = lazy(
    () => import("../modules/apps/transactions/TransactionsPage")
  );

  const WarningPage = lazy(
    () => import("../modules/apps/warning-list/WarningPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path="auth/*"
          element={<Navigate to="/apps/user-management/users" />}
        />
        {/* Pages */}

        <Route
          path="/"
          element={<Navigate to="/apps/user-management/users" />}
        />

        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/agent-management/*"
          element={
            <SuspensedView>
              <AgentPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/bonuses/*"
          element={
            <SuspensedView>
              <BonusesPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/*"
          element={
            <SuspensedView>
              <FaqPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/transactions/*"
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/warning/*"
          element={
            <SuspensedView>
              <WarningPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
